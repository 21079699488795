<template>
    <div class="page-body">
        <div class="table-body">
            <div class="table-header">
                <div class="handle-box">
                    <div class="search-input">
                        <table style="width: 100%;">
                            <tr>
                                <td style="width: 80px;">
                                    合同编号:
                                </td>
                                <td>
                                    <el-input v-model="searchItem.contractNum" style="width: 100%;" size="small"
                                              placeholder="请输入合同编号"
                                    ></el-input>
                                </td>
                                <td style="width: 80px;">
                                    客户名称:
                                </td>
                                <td>
                                    <el-input v-model="searchItem.companyName" style="width: 100%;" size="small"
                                              placeholder="请输入客户名称"
                                    ></el-input>
                                </td>
                                <td style="width: 80px;">
                                    销售人员:
                                </td>
                                <td>
                                    <el-select v-model="searchItem.userName" size="small"
                                               style="width: 100%"
                                               filterable
                                               placeholder="请选择销售人员">
                                        <el-option
                                                v-for="item in saleList"
                                                :key="item"
                                                :label="item"
                                                :value="item">
                                        </el-option>
                                    </el-select>
                                </td>
                                <td style="width: 400px;">
                                    <el-button type="primary" style="margin-left: 10px" icon="el-icon-s-tools" size="small"
                                               @click="openListSetting">列表配置
                                    </el-button>
                                    <el-button type="primary" icon="el-icon-search" size="small" @click="searchBtu">搜索
                                    </el-button>
                                    <el-button type="primary" icon="el-icon-refresh" size="small" @click="reset">重置
                                    </el-button>
                                    <el-button type="primary" icon="el-icon-download" size="small" @click="exportExcel">
                                        导出Excel表格
                                    </el-button>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div class="table-info">
                <div class="table-button">
                    <div>
                        <el-table
                                :data="tableData"
                                :max-height="tableHeight"
                                style="width: 100%;height: 100%;"
                                :border="true"
                                :header-cell-style="{ background: '#EBEEF8',height: '45px', 'text-align':'center' }"
                                :cell-style="{padding:'0',height: lineHeight}"
                                fit>
                            <el-table-column type="index" fixed label="序号" width="50" align="center"></el-table-column>
                            <el-table-column prop="contractNum" fixed label="合同号" width="150"
                                             align="center" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="contractName" fixed label="合同名称" width="180" align="left"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="companyName" fixed label="客户名称" width="180" align="left"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="userName" fixed label="销售人员" width="80" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="receivables" label="应收账款(元)" fixed width="120" align="right" show-overflow-tooltip></el-table-column>
                            <el-table-column
                                    v-for="(item, index) in tableTitles"
                                    :key="index"
                                    :width="listWidth"
                                    :label="item.label"
                                    :prop="item.prop"
                                    show-overflow-tooltip>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="table-data">
                    <Pagination :pageSize="option.pageSize" :pageTotal="totalPage" :pageCurrent.sync="option.pageIndex"
                                :pageList="pageList" @handlePageChange="pageChange" @handleSizeChange="pageSizeChange"/>
                </div>
            </div>
        </div>
        <div class="list-setting">
            <el-dialog
                    title="列表显示配置"
                    :visible.sync="listSettingDialog"
                    width="70%"
                    :close-on-click-modal="false"
                    center>
                <el-checkbox-group v-model="checkedListSettings" @change="handleCheckedCitiesChange">
                    <el-checkbox v-for="listSetting in listSettings" :label="listSetting.label"
                                 :key="listSetting.label">
                        {{listSetting.label}}
                    </el-checkbox>
                </el-checkbox-group>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="listSettingDialog = false">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <div class="export-report">
            <el-dialog
                    :title="exportTitle"
                    :visible.sync="exportExcelDialog"
                    width="70%"
                    :close-on-click-modal="false"
                    center>
                <el-table
                        :data="exportData"
                        max-height="400px"
                        size="small"
                        row-class-name="row"
                        cell-class-name="column"
                        :highlight-current-row="true"
                        fit>
                    <el-table-column
                            v-for="(item, index) in exportList"
                            :key="index"
                            :prop="item.prop"
                            width=120
                            :label="item.label" show-overflow-tooltip>
                    </el-table-column>
                </el-table>
                <el-button type="primary" size="small" style="margin-left: 48%;margin-top: 1%"
                           @click.native="exportExcelDialog=false">
                    <download-excel
                            class="export-excel-wrapper"
                            :data="json_data"
                            :fields="json_fields"
                            :name="fileName">
                        <!-- 上面可以自定义自己的样式，还可以引用其他组件button -->
                        导出数据
                    </download-excel>
                </el-button>
            </el-dialog>
        </div>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                pageList: [15,20,50,100] ,
                tableHeight: '',
                fileName: '',
                option: {
                    pageSize: 15,
                    pageIndex: 1,
                },
                listWidth: 145,
                totalPage: 10,
                fields: [],
                values: [],
                json_fields: {},
                json_data: [],
                json_meta: [
                    [
                        {
                            " key ": " charset ",
                            " value ": " utf- 8 "
                        }
                    ]
                ],
                lineHeight: '41px',
                searchItem: {
                    contractNum: '',
                    companyName: '',
                    userName: '',
                    startDate: '',
                    endDate: ''
                },
                saleList: [],
                exportExcelDialog: false,
                inputWidth: '',
                tableTitles: [],
                listSettingDialog: false,
                listSettings: [
                    {label: "货物收入(元)", prop: "goodsIncome"},
                    {label: "租赁收入(元)", prop: "rentalIncome"},
                    {label: "技术服务收入(元)", prop: "serviceIncome"},
                    {label: "租赁/服务期间", prop: "serveCycle"},
                    {label: "已开票金额(元)", prop: "invoiceAmount"},
                    {label: "未开票金额(元)", prop: "noInvoiceAmount"},
                    {label: "合同总额(元)", prop: "contractReceivables"},
                    {label: "本年回款(元)", prop: "currentYearRecoveries"},
                    {label: "往年回款(元)", prop: "recentYearRecoveries"},
                    {label: "累计回款(元)", prop: "totalRecoveries"},
                    {label: "合同应收账款(元)", prop: "contractAmount"},
                    {label: "财务应收账款(元)", prop: "financeReceivables"},
                    {label: "回款率", prop: "amountRate"},
                    {label: "是否分批发货", prop: "isMultipleSend"},
                    {label: "到货日期", prop: "arriveTime"},
                    {label: "验收日期", prop: "checkTime"},
                    {label: "质保日期", prop: "qualityGuaranteeTime"},
                    {label: "统计时间", prop: "statisticalTime"},
                ],
                exportList: [
                    {label: "合同号", prop: "contractNum"},
                    {label: "合同名称", prop: "contractName"},
                    {label: "客户名称", prop: "companyName"},
                    {label: "销售人员", prop: "userName"},
                    {label: "合同总额(元)", prop: "contractReceivables"},
                    {label: "应收账款(元)", prop: "receivables"},
                    {label: "货物收入(元)", prop: "goodsIncome"},
                    {label: "租赁收入(元)", prop: "rentalIncome"},
                    {label: "技术服务收入(元)", prop: "serviceIncome"},
                    {label: "租赁/服务期间", prop: "serveCycle"},
                    {label: "已开票金额(元)", prop: "invoiceAmount"},
                    {label: "未开票金额(元)", prop: "noInvoiceAmount"},
                    {label: "本年回款(元)", prop: "currentYearRecoveries"},
                    {label: "往年回款(元)", prop: "recentYearRecoveries"},
                    {label: "累计回款(元)", prop: "totalRecoveries"},
                    {label: "合同应收账款(元)", prop: "contractAmount"},
                    {label: "财务应收账款(元)", prop: "financeReceivables"},
                    {label: "回款率", prop: "amountRate"},
                    {label: "1月回款(元)", prop: "JanAmount"},
                    {label: "2月回款(元)", prop: "FebAmount"},
                    {label: "3月回款(元)", prop: "MarAmount"},
                    {label: "4月回款(元)", prop: "AprAmount"},
                    {label: "5月回款(元)", prop: "MayAmount"},
                    {label: "6月回款(元)", prop: "JunAmount"},
                    {label: "7月回款(元)", prop: "JulAmount"},
                    {label: "8月回款(元)", prop: "AugAmount"},
                    {label: "9月回款(元)", prop: "SeptAmount"},
                    {label: "10月回款(元)", prop: "OctAmount"},
                    {label: "11月回款(元)", prop: "NovAmount"},
                    {label: "12月回款(元)", prop: "DecAmount"},
                    {label: "是否分批发货", prop: "isMultipleSend"},
                    {label: "到货日期", prop: "arriveTime"},
                    {label: "验收日期", prop: "checkTime"},
                    {label: "质保日期", prop: "qualityGuaranteeTime"},
                    {label: "统计时间", prop: "statisticalTime"},
                ],
                checkedListSettings: [
                    '租赁/服务期间', '累计回款(元)', '本年回款(元)','合同总额(元)', '已开票金额(元)', '合同应收账款(元)', '财务应收账款(元)', '回款率'
                ],
                tableData: [],
                exportData: [],
                arr: [],
                exportTitle: ''
            }
        },
        methods: {
            pageChange(option) {
                this.tableData = [];
                this.searchItem.pageIndex = option;
                this.searchItem.pageSize = this.option.pageSize;
                this.option.pageIndex = option;
                this.$api.getFinancialReport(this.searchItem).then(res => {
                    if (res.code == 200) {
                        for (var tableItem of res.data.content) {
                            var rentalIncome = tableItem.contractReceivables - tableItem.goodsIncome;
                            var noInvoiceAmount = tableItem.contractReceivables - tableItem.invoiceAmount;
                            var recentYearRecoveries = tableItem.totalRecoveries - tableItem.currentYearRecoveries;
                            var item = {
                                contractNum: tableItem.contractNum,
                                contractName: tableItem.contractName,
                                companyName: tableItem.companyName,
                                userName: tableItem.userName,
                                receivables: tableItem.receivables == 0 ? 0 : (tableItem.receivables / 100).toFixed(2),
                                contractReceivables: tableItem.contractReceivables == 0 ? 0 : (tableItem.contractReceivables / 100).toFixed(2),
                                goodsIncome: tableItem.goodsIncome == 0 ? 0 : (tableItem.goodsIncome / 100).toFixed(2),
                                rentalIncome: rentalIncome == 0 ? 0 : (rentalIncome / 100).toFixed(2),
                                serviceIncome: 0,
                                //租赁/服务时间
                                serveCycle: tableItem.startDate + '~' + tableItem.endDate,
                                //已开票金额
                                invoiceAmount: tableItem.invoiceAmount == 0 ? 0 : (tableItem.invoiceAmount / 100).toFixed(2),
                                //未开票
                                noInvoiceAmount: noInvoiceAmount == 0 ? 0 : (noInvoiceAmount / 100).toFixed(2),
                                //本年回款
                                currentYearRecoveries: tableItem.currentYearRecoveries == 0 ? 0 : (tableItem.currentYearRecoveries / 100).toFixed(2),
                                
                                recentYearRecoveries: recentYearRecoveries == 0 ? 0 : (recentYearRecoveries / 100).toFixed(2),
                                //累计回款
                                totalRecoveries: tableItem.totalRecoveries == 0 ? 0 : (tableItem.totalRecoveries / 100).toFixed(2),
                                //合同应收账款
                                contractAmount: ((tableItem.contractReceivables-tableItem.totalRecoveries) / 100).toFixed(2),
                                //财务应收账款
                                financeReceivables: ((tableItem.invoiceAmount-tableItem.totalRecoveries) / 100).toFixed(2),
                                amountRate: tableItem.contractReceivables == 0 ? 0 : ((tableItem.totalRecoveries / tableItem.contractReceivables) * 100).toFixed() + '%',
                                isMultipleSend: tableItem.isMultipleSend == 0 ? '否' : '是',
                                arriveTime: tableItem.arriveTime,
                                checkTime: tableItem.checkTime,
                                qualityGuaranteeTime: tableItem.qualityGuaranteeTime,
                                statisticalTime: tableItem.statisticalTime
                            }
                            this.tableData.push(item);
                            this.json_data.push(item)
                        }
                        this.totalPage = res.data.totalElements;
                    }
                })
            },
            pageSizeChange(val){
                this.tableData = [];
                this.searchItem.pageSize = val;
                this.option.pageSize = val;
                this.$api.getFinancialReport(this.searchItem).then(res => {
                    if (res.code == 200) {
                        for (var tableItem of res.data.content) {
                            var rentalIncome = tableItem.contractReceivables - tableItem.goodsIncome;
                            var noInvoiceAmount = tableItem.contractReceivables - tableItem.invoiceAmount;
                            var recentYearRecoveries = tableItem.totalRecoveries - tableItem.currentYearRecoveries;
                            var item = {
                                contractNum: tableItem.contractNum,
                                contractName: tableItem.contractName,
                                companyName: tableItem.companyName,
                                userName: tableItem.userName,
                                receivables: tableItem.receivables == 0 ? 0 : (tableItem.receivables / 100).toFixed(2),
                                contractReceivables: tableItem.contractReceivables == 0 ? 0 : (tableItem.contractReceivables / 100).toFixed(2),
                                goodsIncome: tableItem.goodsIncome == 0 ? 0 : (tableItem.goodsIncome / 100).toFixed(2),
                                rentalIncome: rentalIncome == 0 ? 0 : (rentalIncome / 100).toFixed(2),
                                serviceIncome: 0,
                                //租赁/服务时间
                                serveCycle: tableItem.startDate + '~' + tableItem.endDate,
                                //已开票金额
                                invoiceAmount: tableItem.invoiceAmount == 0 ? 0 : (tableItem.invoiceAmount / 100).toFixed(2),
                                //未开票
                                noInvoiceAmount: noInvoiceAmount == 0 ? 0 : (noInvoiceAmount / 100).toFixed(2),
                                //本年回款
                                currentYearRecoveries: tableItem.currentYearRecoveries == 0 ? 0 : (tableItem.currentYearRecoveries / 100).toFixed(2),
                                
                                recentYearRecoveries: recentYearRecoveries == 0 ? 0 : (recentYearRecoveries / 100).toFixed(2),
                                //累计回款
                                totalRecoveries: tableItem.totalRecoveries == 0 ? 0 : (tableItem.totalRecoveries / 100).toFixed(2),
                                //合同应收账款
                                contractAmount: ((tableItem.contractReceivables-tableItem.totalRecoveries) / 100).toFixed(2),
                                //财务应收账款
                                financeReceivables: ((tableItem.invoiceAmount-tableItem.totalRecoveries) / 100).toFixed(2),
                                amountRate: tableItem.contractReceivables == 0 ? 0 : ((tableItem.totalRecoveries / tableItem.contractReceivables) * 100).toFixed() + '%',
                                isMultipleSend: tableItem.isMultipleSend == 0 ? '否' : '是',
                                arriveTime: tableItem.arriveTime,
                                checkTime: tableItem.checkTime,
                                qualityGuaranteeTime: tableItem.qualityGuaranteeTime,
                                statisticalTime: tableItem.statisticalTime
                            }
                            this.tableData.push(item);
                            this.json_data.push(item)
                        }
                        this.totalPage = res.data.totalElements;
                    }
                })
            },
            openListSetting() {
                this.listSettingDialog = true;

            },
            searchBtu() {
                this.pageChange(1);
            },
            reset() {
                this.searchItem = {
                    contractNum: '',
                    companyName: '',
                    salesName: '',
                    startDate: '',
                    endDate: ''
                }
                this.pageChange(1);
            },
            exportExcel() {
                var date = new Date();
                var year = date.getFullYear();
                var month = (date.getMonth() + 1) < 10 ? 0 + '' + (date.getMonth() + 1) : ((date.getMonth() + 1))
                var date = date.getDate() < 10 ? 0 + '' + date.getDate() : (date.getDate())
                this.fileName = "财务报表" + year + month + date;
                this.exportTitle = "财务报表" + year + month + date;
                this.exportData = [];
                this.exportExcelDialog = true;
                this.searchItem.pageSize = 1000;
                this.$api.getFinancialReport(this.searchItem).then(res => {
                    if (res.code == 200) {
                        for (var tableItem of res.data.content) {
                            var rentalIncome = tableItem.contractReceivables - tableItem.goodsIncome;
                            var noInvoiceAmount = tableItem.contractReceivables - tableItem.invoiceAmount;
                            var recentYearRecoveries = tableItem.totalRecoveries - tableItem.currentYearRecoveries;
                            var item = {
                                contractNum: tableItem.contractNum,
                                contractName: tableItem.contractName,
                                companyName: tableItem.companyName,
                                userName: tableItem.userName,
                                receivables: tableItem.receivables == 0 ? 0 : (tableItem.receivables / 100).toFixed(2),
                                contractReceivables: tableItem.contractReceivables == 0 ? 0 : (tableItem.contractReceivables / 100).toFixed(2),
                                goodsIncome: tableItem.goodsIncome == 0 ? 0 : (tableItem.goodsIncome / 100).toFixed(2),
                                rentalIncome: rentalIncome == 0 ? 0 : (rentalIncome / 100).toFixed(2),
                                serviceIncome: 0,
                                //租赁/服务时间
                                serveCycle: tableItem.startDate + '~' + tableItem.endDate,
                                //已开票金额
                                invoiceAmount: tableItem.invoiceAmount == 0 ? 0 : (tableItem.invoiceAmount / 100).toFixed(2),
                                //未开票
                                noInvoiceAmount: noInvoiceAmount == 0 ? 0 : (noInvoiceAmount / 100).toFixed(2),
                                //本年回款
                                currentYearRecoveries: tableItem.currentYearRecoveries == 0 ? 0 : (tableItem.currentYearRecoveries / 100).toFixed(2),
                                
                                recentYearRecoveries: recentYearRecoveries == 0 ? 0 : (recentYearRecoveries / 100).toFixed(2),
                                //累计回款
                                totalRecoveries: tableItem.totalRecoveries == 0 ? 0 : (tableItem.totalRecoveries / 100).toFixed(2),
                                //合同应收账款
                                contractAmount: ((tableItem.contractReceivables-tableItem.totalRecoveries) / 100).toFixed(2),
                                //财务应收账款
                                financeReceivables: ((tableItem.invoiceAmount-tableItem.totalRecoveries) / 100).toFixed(2),
                                amountRate: tableItem.contractReceivables == 0 ? 0 : ((tableItem.totalRecoveries / tableItem.contractReceivables) * 100).toFixed() + '%',
                                isMultipleSend: tableItem.isMultipleSend == 0 ? '否' : '是',
                                arriveTime: tableItem.arriveTime,
                                checkTime: tableItem.checkTime,
                                qualityGuaranteeTime: tableItem.qualityGuaranteeTime,
                                statisticalTime: tableItem.statisticalTime,
                                JanAmount: tableItem.recoveriesMonthList[0] == 0 ? 0 : (tableItem.recoveriesMonthList[0] / 100).toFixed(2),
                                FebAmount: tableItem.recoveriesMonthList[1] == 0 ? 0 : (tableItem.recoveriesMonthList[1] / 100).toFixed(2),
                                MarAmount: tableItem.recoveriesMonthList[2] == 0 ? 0 : (tableItem.recoveriesMonthList[2] / 100).toFixed(2),
                                AprAmount: tableItem.recoveriesMonthList[3] == 0 ? 0 : (tableItem.recoveriesMonthList[3] / 100).toFixed(2),
                                MayAmount: tableItem.recoveriesMonthList[4] == 0 ? 0 : (tableItem.recoveriesMonthList[4] / 100).toFixed(2),
                                JunAmount: tableItem.recoveriesMonthList[5] == 0 ? 0 : (tableItem.recoveriesMonthList[5] / 100).toFixed(2),
                                JulAmount: tableItem.recoveriesMonthList[6] == 0 ? 0 : (tableItem.recoveriesMonthList[6] / 100).toFixed(2),
                                AugAmount: tableItem.recoveriesMonthList[7] == 0 ? 0 : (tableItem.recoveriesMonthList[7] / 100).toFixed(2),
                                SeptAmount: tableItem.recoveriesMonthList[8] == 0 ? 0 : (tableItem.recoveriesMonthList[8] / 100).toFixed(2),
                                OctAmount: tableItem.recoveriesMonthList[9] == 0 ? 0 : (tableItem.recoveriesMonthList[9] / 100).toFixed(2),
                                NovAmount: tableItem.recoveriesMonthList[10] == 0 ? 0 : (tableItem.recoveriesMonthList[10] / 100).toFixed(2),
                                DecAmount: tableItem.recoveriesMonthList[11] == 0 ? 0 : (tableItem.recoveriesMonthList[11] / 100).toFixed(2),
                            }
                            this.exportData.push(item);
                        }
                        this.json_data = this.exportData
                    }
                })
            },
            handleCheckedCitiesChange(value) {
                this.tableTitles = [];
                let checkedCount = value.length;
                this.checkAll = checkedCount === this.listSettings.length;
                this.isIndeterminate = checkedCount > 0 && checkedCount < this.listSettings.length;
                for (var listSetting of this.listSettings) {
                    for (var checkedListSetting of value) {
                        if (listSetting.label == checkedListSetting) {
                            this.tableTitles.push(listSetting);
                        }
                    }
                }
            },
        },
        created() {
            this.searchBtu();
            this.$api.getSaleList().then(res => {
                if (res.code == 200){
                    this.saleList = res.data
                }
            })
            this.tableHeight = (window.innerHeight)*0.71
            this.inputWidth = document.body.scrollWidth * 0.25 + 'px';
            for (var listSetting of this.listSettings) {
                for (var checkedListSetting of this.checkedListSettings) {
                    if (listSetting.label == checkedListSetting) {
                        this.tableTitles.push(listSetting)
                    }
                }
            }
            this.exportList.map((item, i) => {
                this.json_fields[item.label] = {
                    field: item.prop,
                    callback: value => {
                        return value;
                    }
                }
            });
        }
    }
</script>

<style scoped>
    .page-body {
        background-color: #FFFFFF;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .table-body {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .table-header {
        height: 50px;
        background-color: #FFFFFF;
    }

    .handle-box {
        padding: 10px;
        margin-top: 10px;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .table-info {
        flex: 1;
        margin-top: 8px;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
    }

    .table-button {
        flex: 1;
        margin: 0 1%;
        margin-top: 10px;
    }

    .table-data {
        height: 6%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    td{
        white-space: nowrap; /*控制单行显示*/
        overflow: hidden; /*超出隐藏*/
        text-overflow: ellipsis; /*隐藏的字符用省略号表示*/
    }

    .span-text {
        width: 80px;
        padding-top: 5px;
        text-align: center;
    }

    .search-input {
        display: flex;
    }
</style>